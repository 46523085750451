// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { ColorThemePlugin } from '../services/vuestic-ui'
import store from '../store/index'
import router from '../router/index'
import { VuesticPlugin } from '../services/vuestic-ui/components'
import '../i18n/index'
// import YmapPlugin from 'vue-yandex-maps'
import VueClipboard from 'vue-clipboard2'
// import Vuetify from 'vuetify/lib'

import '../metrics'
import '../registerServiceWorker'
import axios from 'axios';
import VueAxios from 'vue-axios';

import { consoleBuildInfo } from 'vue-cli-plugin-build-info/plugin'

consoleBuildInfo()

Vue.use(VuesticPlugin)
    // Vue.use(YmapPlugin)
Vue.use(VueClipboard)
    // Vue.use(Vuetify)
Vue.use(ColorThemePlugin, {
    // override colors here.
})
Vue.use(VueAxios, axios);
// axios.defaults.baseURL = 'https://api.ifudou.net'
// 中国馆
axios.defaults.baseURL = 'https://media.dyeego.com/api'
// axios.defaults.baseURL = 'http://localhost:4000'


router.beforeEach((to, from, next) => {
    // store.commit('setLoading', true)
    // next()
    if (to.meta.needLogin) {
        //从cookie中获取用户信息，判断是否已登录
        console.log(sessionStorage['access_token'])
        // console.log(sessionStorage['access_token']!==null)
        if (sessionStorage['access_token']) {
            console.log("already login");
            if (to.meta.auth) {
                //从cookie中获取用户类型，判断是否有登陆权限
                console.log(sessionStorage['user_role'])
                // console.log(sessionStorage['access_token']!==null)
                if (sessionStorage['user_role']==="\"sa\"" || sessionStorage['user_role']==="\"admin\"") {
                    console.log("has auth");
                    next(); //表示已经登录
                } else {

                }
              } else {
                //表示不需要登录
                next(); //继续往后走
              }

        } else {
          //未登录
          //next可以传递一个路由对象作为参数 表示需要跳转到的页面
          next({
            name: "login",
            // query: {redirect: to.meta.redirect} //登录后再跳回此页面时要做的配置
          });
        }
      } else {
        //表示不需要登录
        next(); //继续往后走
      }

})

router.afterEach((to, from) => {
    store.commit('setLoading', false)
})

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})
