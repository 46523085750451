<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="va-icon-vuestic"
    width="400"
    height="31"
    viewBox="0 0 400 34"
  >
    <defs>
      <linearGradient :id="'ORIGINAL'" x1="0%" y1="50%" y2="50%">
        <stop offset="0%" stop-color="#4AE387" />
        <stop offset="100%" stop-color="#C8EA13" />
      </linearGradient>
      <linearGradient :id="'CORPORATE'" x1="0%" y1="50%" y2="50%">
        <stop offset="0%" stop-color="#74BBFF" />
        <stop offset="100%" stop-color="#6E85E8" />
      </linearGradient>
    </defs>
    <!-- <text>hello</text> -->
    <g fill="none" fill-rule="evenodd" transform="scale(2)">
      <text
        x="0"
        y="16"
        :fill="`url(#${themeGradientId})`"
        fill-rule="nonzero"
        style="font-size: 16px; font-weight: bold;"
      >AI 图 像 合 成 销 售 系 统</text>
    </g>
  </svg>
</template>
<script>
export default {
  name: "VaIconVuestic",
  inject: ["contextConfig"],
  computed: {
    themeGradientId() {
      return this.contextConfig.invertedColor ? "CORPORATE" : "ORIGINAL";
    },
    textColor() {
      return this.contextConfig.invertedColor ? "#6E85E8" : "#E4FF32";
    },
  },
};
</script>

<style lang="scss">
.va-icon-vuestic {
  .st0 {
    fill: #4ae387;
  }
}
</style>
