<template>
  <nav class="app-navbar" :style="navbarStyle">
    <div class="app-navbar__content row">
      <div class="app-navbar__menu-container">
        <va-icon-menu
          class="app-navbar__menu"
          v-if="!minimized && !isTopBar"
          @click.native="$emit('update:minimized', !minimized)"
          :color="contextConfig.invertedColor ? $themes.gray : 'white'"
        />

        <va-icon-menu-collapsed
          class="app-navbar__menu"
          v-if="minimized && !isTopBar"
          @click.native="$emit('update:minimized', !minimized)"
          :color="contextConfig.invertedColor ? $themes.gray : 'white'"
        />

        <router-link class="app-navbar__logo mr-3" to="/">
          <va-icon-vuestic />
        </router-link>
      </div>
      <!-- <div class="app-navbar__center lg5 md4">
        <span
          class="app-navbar__text"
          :style="{color: this.$themes.gray}"
        >
          {{$t('navbar.messageUs')}}&nbsp;
          <a
            href="mailto:hello@epicmax.co"
            target="_blank"
            class="app-navbar__mailto-link"
            :style="{color: this.$themes.primary}"
          >
            hello@epicmax.co
          </a>
        </span>
        <va-button
          href="https://github.com/epicmaxco/vuestic-admin"
          color="#000000"
          class="app-navbar__button"
          icon="fa fa-github"
          target="_blank"
        >
          {{$t('navbar.repository')}}
        </va-button>
      </div>-->
      <app-navbar-actions
        class="app-navbar__actions md5 lg4"
        :user-name="userName"
        :is-top-bar.sync="isTopBarProxy"
      />
    </div>
    <div class="app-navbar__shape" :style="shapeStyle"></div>
  </nav>
</template>

<script>
import VaIconVuestic from "../../../iconset/VaIconVuestic";
import VaIconMenu from "../../../iconset/VaIconMenu";
import VaIconMenuCollapsed from "../../../iconset/VaIconMenuCollapsed";
import AppNavbarActions from "./components/AppNavbarActions";
import { colorShiftHsl, ColorThemeMixin } from "../../../services/vuestic-ui";

export default {
  name: "app-navbar",
  mixins: [ColorThemeMixin],
  inject: ["contextConfig"],
  components: {
    VaIconVuestic,
    VaIconMenu,
    VaIconMenuCollapsed,
    AppNavbarActions,
  },
  props: {
    isTopBar: {
      type: Boolean,
      required: true,
    },
    minimized: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      userName: sessionStorage['username'],
    };
  },
  computed: {
    isTopBarProxy: {
      get() {
        return this.isTopBar;
      },
      set(isTopBar) {
        this.$emit("update:isTopBar", isTopBar);
      },
    },
    minimizedProxy: {
      get() {
        return this.minimized;
      },
      set(minimized) {
        this.$emit("update:minimized", minimized);
      },
    },
    navbarStyle() {
      const style = {
        backgroundColor: "white",
      };

      if (this.contextConfig.gradient) {
        style.backgroundColor = colorShiftHsl(this.$themes.secondary, {
          s: -13,
          l: 15,
        }).css;
      }

      if (this.contextConfig.shadow === "sm") {
        style.boxShadow = !this.isTopBar
          ? "0 2px 3px 0 rgba(52, 56, 85, 0.25)"
          : null;
      }
      return style;
    },

    shapeStyle() {
      return {
        borderTopColor: this.contextConfig.gradient
          ? colorShiftHsl(this.$themes.secondary, {
              h: -1,
              s: -11,
              l: 10,
            }).css
          : "transparent",
      };
    },
  },
};
</script>

<style lang="scss">
$nav-border-side-width: 3.1875rem;

.app-navbar {
  z-index: 1;
  display: flex;
  padding: 1rem 1rem;
  transition: background-color 0.3s ease; /* sidebar's bg color transitions as well -> consistency */

  &__content {
    z-index: 1;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 3rem;
  }

  &__text {
    color: $lighter-gray;
  }

  &__button {
    width: 10rem;
    margin: 0 0 0 1rem !important;
    font-weight: bold;

    .va-button__content__icon-left.fa-github {
      font-size: 1.5rem;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    font-size: 1rem;
    cursor: pointer;
  }

  &__menu-container {
    display: flex;
    flex-wrap: nowrap;
    height: 1.5rem;
  }

  &__logo {
    align-items: center;
    width: auto;
    height: auto;

    & * {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  &__actions {
    justify-content: flex-end;
  }

  &__mailto-link:hover {
    filter: brightness(85%);
  }

  &__shape {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 33%;
    max-width: 467px;
    height: 0;
    margin: auto;
    border-top: 4.215rem solid transparent; // hardcoded size
    border-right: $nav-border-side-width solid transparent;
    border-left: $nav-border-side-width solid transparent;
    transition: border-top-color 0.3s ease; /* sidebar's bg color transitions as well -> consistency */
  }

  @include media-breakpoint-down(lg) {
    &__button {
      display: none !important;
    }
  }

  @include media-breakpoint-down(md) {
    &__center {
      display: none !important;
    }
  }

  @include media-breakpoint-down(sm) {
    &__content {
      align-items: flex-end;
    }

    &__actions {
      justify-content: space-between;
      width: 100%;
      margin-top: 1.25rem;
    }

    &__shape {
      display: none;
    }
  }
}
</style>
