export const navigationRoutesAdmin = {
    root: {
        name: '/',
        displayName: 'navigationRoutes.home',
    },
    routes: [{
            name: 'shpage',
            displayName: 'menu.shpage',
            meta: {
                iconClass: 'vuestic-iconset vuestic-iconset-image',
            },
        },
        {
            name: 'viewpage',
            displayName: 'menu.viewpage',
            meta: {
                iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
            },
        },
        {
            name: 'upload',
            displayName: 'menu.upload',
            meta: {
                iconClass: 'vuestic-iconset vuestic-iconset-files',
            },
        },
        {
            name: 'autoupload',
            displayName: 'menu.autoupload',
            meta: {
                iconClass: 'vuestic-iconset vuestic-iconset-files',
            },
        },
        {
            name: 'origin',
            displayName: 'menu.origin',
            meta: {
                iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
            },
        },

        {
            name: 'Manage',
            displayName: 'menu.manage',
            meta: {
                iconClass: 'vuestic-iconset vuestic-iconset-statistics',
            },
            disabled: true,
            children: [{
                    name: 'stat',
                    displayName: 'menu.stat',
                },
                {
                    name: 'order',
                    displayName: 'menu.order',
                },
                {
                    name: 'user',
                    displayName: 'menu.user',
                },
            ],
        },

    ],
}