import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'

Vue.use(Router)

const EmptyParentComponent = {
    template: '<router-view></router-view>',
}

const demoRoutes = []

export default new Router({
    // mode: 'history',
    routes: [
        ...demoRoutes,
        {
            path: '*',
            redirect: { name: 'login' },
        },
        {
            path: '/auth',
            component: AuthLayout,
            children: [{
                    name: 'login',
                    path: 'login',
                    component: () =>
                        import ('../components/auth/login/Login.vue'),
                },
                {
                    path: '',
                    redirect: { name: 'login' },
                },
            ],
        },
        {
            name: 'Admin',
            path: '/admin',
            component: AppLayout,
            children: [{
                    name: 'shpage',
                    path: 'shpage',
                    component: () =>
                        import ('../components/shpage/Shpage.vue'),
                    default: true,
                },
                {
                    name: 'viewpage',
                    path: 'viewpage',
                    component: () =>
                        import ('../components/viewpage/Viewpage.vue'),
                    default: true,
                    meta: {needLogin:true},
                },
                {
                    name: 'upload',
                    path: 'upload',
                    component: () =>
                        import ('../components/upload/Upload.vue'),
                    default: true,
                    meta: {needLogin:true},
                },
                {
                    name: 'autoupload',
                    path: 'autoupload',
                    component: () =>
                        import ('../components/autoupload/Autoupload.vue'),
                    default: true,
                    meta: {needLogin:true},
                },
                {
                    name: 'origin',
                    path: 'origin',
                    component: () =>
                        import ('../components/origin/Origin.vue'),
                    default: true,
                    meta: {needLogin:true},
                },
                {
                    name: 'statistics',
                    path: 'statistics',
                    component: EmptyParentComponent,
                    children: [{
                            name: 'stat',
                            path: 'stat',
                            component: () =>
                                import ('../components/statistics/Stat.vue'),
                            meta: {
                                needLogin:true,
                                auth:true,
                            },

                        },
                        {
                            name: 'order',
                            path: 'order',
                            component: () =>
                                import ('../components/statistics/Order.vue'),
                            meta: {
                                needLogin:true,
                                auth:true,
                            },
    

                        },
                        {
                            name: 'user',
                            path: 'user',
                            component: () =>
                                import ('../components/statistics/User.vue'),
                            meta: {
                                needLogin:true,
                                auth:true,
                            },
    
                        },
                    ],
                },
            ],
        },
    ],
})